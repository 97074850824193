<template>
  <div class="viewContainer">
    <v-container
      class="pa-4 pa-sm-8"
    >
      <v-card class="content-tabs-wrapper px-4 px-sm-8 py-4 py-sm-8" elevation="0">
        <div class="content-container">
          <v-card flat tile>
            <p class="text-subtitle-1 mb-4">{{ $t('verify_service_head') }}</p>
            <ValidationObserver ref="verifyServiceForm" v-slot="{ invalid }">
              <v-form 
                id="verifyServiceForm"
                lazy-validation
                @submit.prevent="verifyService()"
                autocomplete="off"
                method="post"
                accept-charset="UTF-8"
              >
                <div class="position-relative">
                  <v-btn 
                    v-if="verifyServiceForm.open"
                    small
                    class="no-caps close-qr-reader"
                    @click="closeQrReader()"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <qrcode-stream v-if="verifyServiceForm.open" @detect="onDetectService" :multiple="false" id="couponScanner" tabindex="-1" />
                  <!-- <x-text-field
                    :name="$t('coupon')"
                    v-model="verifyServiceForm.code"
                    id="verifyServiceForm.code"
                    ref="verifyServiceForm.code"
                    rules="required"
                    class="title d-none"
                    :placeholder="$t('code')"
                    prepend-inner-icon="mdi-qrcode"
                    :loading="inputLoading"
                    @click:prepend-inner="openServiceCamera"
                    :label="$t('code')"
                  /> -->
                  <x-text-field
                    :name="$t('code')"
                    v-model="verifyServiceForm.code"
                    id="verifyServiceForm.code"
                    ref="verifyServiceForm.code"
                    rules="required"
                    class="title"
                    :placeholder="$t('code')"
                    prepend-inner-icon="mdi-qrcode"
                    :loading="inputLoading"
                    @click:prepend-inner="openServiceCamera"
                    :label="$t('code')"
                  />

                  <v-btn
                    type="submit"
                    depressed 
                    block
                    rounded
                    x-large
                    color="primary"
                    class="mt-4 no-caps"
                    :loading="verifyServiceForm.loading" :disabled="verifyServiceForm.loading || invalid"
                  >
                    {{ $t('verify') }} <v-icon right>mdi-arrow-right</v-icon>
                  </v-btn>
                </div>
              </v-form>
            </ValidationObserver>
            <p class="text-subtitle-1 mt-4 mb-0" v-if="supportsQrCamera()">{{ $t('redeem_points_footer_qr') }}</p>
            <p class="text-subtitle-1 mt-4 mb-0" v-if="!supportsQrCamera()">{{ $t('redeem_points_footer') }}</p>
          </v-card>
        </div>
      </v-card>
    </v-container>

    <v-dialog v-model="verifyServiceDialog" :fullscreen="$vuetify.breakpoint.xsOnly" max-width="360" scrollable>
      <v-card v-if="confirm_service && !success_process_success">
        <v-card-title>{{ $t('used_service') }}</v-card-title>
        <v-card-text>
          <div class="d-flex justify-content-between align-center">
            <span class="text-body-1">{{ $t('membership') }}:</span>
            <span class="text-h6">{{ msg_service.membership_name }}</span>
          </div>
          <div class="d-flex justify-content-between align-center">
            <span class="text-body-1">{{ $t('membership_service') }}:</span>
            <span class="text-h6">{{ msg_service.membership_service_name }}</span>
          </div>
          <div class="d-flex justify-content-between align-center">
            <span class="text-body-1">{{ $t('max_points') }}:</span>
            <span class="text-h6">{{ msg_service.max_points }}</span>
          </div>
          <div class="d-flex justify-content-between align-center">
            <span class="text-body-1">{{ $t('min_points') }}:</span>
            <span class="text-h6">{{ msg_service.min_points }}</span>
          </div>
          <div class="d-flex justify-content-between align-center">
            <span class="text-body-1">{{ $t('points') }}:</span>
            <span class="text-h6 success--text">{{ msg_service.points }}</span>
          </div>
          <div class="d-flex justify-content-between align-center">
            <span class="text-body-1">{{ $t('customer_name') }}:</span>
            <span class="text-h6 success--text">{{ msg_service.customerName }}</span>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn 
            color="" 
            class="no-caps" 
            text 
            rounded
            @click="verifyServiceDialog = false"
          >{{ $t('cancel') }}</v-btn>
          <v-btn 
            color="primary" 
            class="no-caps" 
            depressed 
            rounded
            :loading="msg_service.loading" 
            :disabled="msg_service.loading" 
            @click="processService()"
          >{{ $t('used_service') }}</v-btn>
        </v-card-actions>
      </v-card>

        <v-card v-if="success_process_success">
          <v-card-title>{{ $t('used_service_success') }}</v-card-title>
          <v-card-text class="justify-center">
            <div class="">
              <div class="d-flex justify-content-between align-center">
                <span class="text-body-1">{{ $t('used_service_success') }}:</span>
                <span class="text-h6"><v-icon color="success">mdi-check</v-icon></span>
              </div>
              <div class="d-flex justify-content-between align-center">
                <span class="text-body-1">{{ $t('membership') }}:</span>
                <span class="text-h6">{{ msg_service.membership_name }}</span>
              </div>
              <div class="d-flex justify-content-between align-center">
                <span class="text-body-1">{{ $t('membership_service') }}:</span>
                <span class="text-h6">{{ msg_service.membership_service_name }}</span>
              </div>
              <div class="d-flex justify-content-between align-center">
                <span class="text-body-1">{{ $t('max_points') }}:</span>
                <span class="text-h6">{{ msg_service.max_points }}</span>
              </div>
              <div class="d-flex justify-content-between align-center">
                <span class="text-body-1">{{ $t('discount') }}:</span>
                <span class="text-h6">{{ msg_service.min_points }}</span>
              </div>
              <div class="d-flex justify-content-between align-center">
                <span class="text-body-1">{{ $t('points') }}:</span>
                <span class="text-h6">{{ msg_service.points }}</span>
              </div>
              <div class="d-flex justify-content-between align-center">
                <span class="text-body-1">{{ $t('customer_name') }}:</span>
                <span class="text-h6">{{ msg_service.customerName }}</span>
              </div>
            </div>
          </v-card-text>
          
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="" rounded text @click.native="cancel" class="no-caps">{{ $t('cancel') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { supportsQrCamera, copyStringToClipboard } from '@/utils/helpers'
import { QrcodeStream } from 'vue-qrcode-reader'
import { mask } from 'vue-the-mask'

export default {
  components: {
    QrcodeStream,
  },
  data: () => ({
    locale: 'th',
    verifyServiceDialog: false,
    tab: 'tab-0',
    tabImg: 'about:blank',
    tabImgHeight: null,
    tabImgDefaultHeight: 780,
    tabImgAspectRation: 1.7778,
    tabImgs: [],
    inputLoading: false,
    status_service: false,
    confirm_service: false,
    verifyServiceForm: {
      open: false,
      success: false,
      loading: false,
      code: ''
    },
    msg_service: {
      open: false,
      loading: false,
      points: 0,
      value: 0,
      customer_number: '',
      customerName: '',
    },
    success_process_success: false
  }),
  directives: {
    mask
  },
  created () {
    // Set locale
    this.locale = 'th'
    this.moment.locale(this.locale.substr(0,2))

  },
  methods: {
    supportsQrCamera,
    copyStringToClipboard,
    formatNumber (number) {
      return new Intl.NumberFormat(this.locale.replace('_', '-')).format(number)
    },
    formatCurrency (number, currency) {
      return new Intl.NumberFormat(this.locale.replace('_', '-'), {style: 'currency', currency: currency}).format(number)
    },
    closeQrReader() {
      this.verifyServiceForm.open = false
    },
    openQrCamera () {
      //this.$el.querySelector('#qrScanner').click()
      this.verifyCodeForm.open = true
    },
    openServiceCamera () {
      //this.$el.querySelector('#couponScanner').click()
      this.verifyServiceForm.open = true
    },
    async onDetectService (promise) {
      this.$refs['verifyServiceForm'].reset()
      this.inputLoading = true
      try {
        const {
          imageData,    // raw image data of image/frame
          content,      // decoded String or null
          location      // QR code coordinates or null
        } = await promise

        if (content === null) {
          this.$refs['verifyServiceForm.code'].applyResult({
            errors: this.$t('camera_no_qr_found'),
            valid: false,
            failedRules: {}
          })
        } else {
          // const url = new window.URL(content)
          // const urlParams = new window.URLSearchParams(url.search)
          // this.verifyServiceForm.code = urlParams.get('c')
          this.verifyServiceForm.code = content
        }
      } catch (error) {
        this.$refs['verifyServiceForm.code'].applyResult({
          errors: error,
          valid: false,
          failedRules: {}
        })
      } finally {
        this.inputLoading = false
        this.verifyServiceForm.open = false
      }
    },
    openQrUpload () {
      //this.$refs.qr_customer_number.$el.click();
      this.msg_service.open = true
    },
    cancel (){
      this.verifyServiceDialog = false
    },
    verifyService () {
      this.success_process_success = false
      this.verifyServiceForm.loading = true
      this.axios
      .post('/business/verify-service', {
        locale: this.locale,
        code: this.verifyServiceForm.code
      })
      .then(response => {
        if (response.data.status === 'success') {
          //console.log(response.data)
          this.verifyServiceDialog = true
          this.confirm_service = true
          this.msg_service.membership_name = response.data.membership_name
          this.msg_service.membership_service_name = response.data.membership_service_name
          this.msg_service.max_points = this.formatNumber(response.data.max_points)
          this.msg_service.min_points = this.formatNumber(response.data.min_points)
          this.msg_service.points = this.formatNumber(response.data.points)
          this.msg_service.customerName = response.data.customer_name
        }
      })
      .catch(error => {
        if (error.response.data.status === 'error') {
          this.$root.$snackbar(error.response.data.msg)
        }
      })
      .finally(() => { 
        window.scrollTo(0,0)
        this.verifyServiceForm.loading = false
      })
    },
    processService () {
      this.msg_service.loading = true
      this.axios
      .post('/business/process-code-service', {
        code: this.verifyServiceForm.code
      })
      .then(response => {
        if (response.data.status === 'success') {
          //console.log(response.data)
          this.verifyServiceForm.code = ''
          this.success_process_success = true
        }
      })
      .catch(error => {
        if (error.response.data.status === 'error') {
          this.$root.$snackbar(error.response.data.msg)
        }
      })
      .finally(() => { 
        window.scrollTo(0,0)
        this.msg_service.loading = false
      })
    }
  }
}
</script>
<style scoped>
  .close-qr-reader{
    position: absolute;
    z-index: 1;
    right: 0;
  }
  .position-relative{
    position: relative;
  }
</style>