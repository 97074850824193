<template>
  <div class="viewContainer">
    <v-container
      class="pa-4 pa-sm-8"
    >
      <v-card class="content-tabs-wrapper px-4 px-sm-8 py-4 py-sm-8" elevation="0">
        <div class="content-container">
          <v-tabs
            :color="($store.state.app.dark) ? 'white' : 'black'"
            class="content-tabs"
            background-color="transparent"
            fixed-tabs
            v-model="tab"
          >
            <v-tabs-slider></v-tabs-slider>
            <v-tab class="no-caps" :ripple="false">
              {{ $t('code') }}
              <!-- <v-icon size="24">mdi-qrcode</v-icon> -->
            </v-tab>
            <v-tab class="no-caps" :ripple="false">
              {{ $t('coupon') }}
              <!-- <v-icon size="24">mdi-ticket</v-icon> -->
            </v-tab>
          </v-tabs>

          <!-- <v-card>
            <v-card-text>
              <v-btn 
              color="grey" 
              text 
              @click.native="changeView('status_code')" 
              :class="'title ma-0 no-caps '+ (status_code ? 'v-btn--active' : '')"
              >
                <v-icon left>mdi-qrcode</v-icon>
                {{ $t('code') }}
              </v-btn>
              <v-btn 
              color="grey" 
              text 
              @click.native="changeView('status_coupon')" 
              :class="'title ma-0 no-caps '+ (status_coupon ? 'v-btn--active' : '')"
              >
                <v-icon left>mdi-ticket</v-icon>
                {{ $t('coupon') }}
              </v-btn>
            </v-card-text>
          </v-card> -->

          <v-tabs-items v-model="tab" class="mt-8">
            <v-tab-item>
              <v-card flat tile>
                <v-alert
                  :value="verifyCodeForm.success"
                  text
                  type="success"
                  icon="mdi-check-circle-outline"
                  class="mb-4"
                >
                  <i18n path="redeem_points_success">
                    <template #points>{{ msg.points }}</template>
                    <template #value><span class="font-weight-medium">{{ msg.value }}</span></template>
                    <template #customerNumber><span class="font-weight-medium">{{ msg.customerNumber }}</span></template>
                    <template #transactions>
                      <router-link class="inherit--text" :to="{ name: 'business.transactions' }"><span class="font-weight-medium">{{ $t('transactions').toLowerCase() }}</span></router-link>
                    </template>
                  </i18n>
                </v-alert>
                <p class="text-subtitle-1 mb-4">{{ $t('redeem_points_head') }}</p>
                <ValidationObserver ref="verifyCodeForm" v-slot="{ invalid }">
                  <v-form 
                    id="verifyCodeForm"
                    lazy-validation
                    @submit.prevent="verifyCode()"
                    autocomplete="off"
                    method="post"
                    accept-charset="UTF-8"
                  >
                    <div class="position-relative">
                      <v-btn 
                        v-if="verifyCodeForm.open"
                        small
                        class="no-caps close-qr-reader"
                        @click="closeQrReader('code')"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                      <!-- <qrcode-capture @detect="onDetect" :multiple="false" id="qrScanner" tabindex="-1" class="d-none" /> -->
                      <qrcode-stream v-if="verifyCodeForm.open" @detect="onDetect" :multiple="false" id="qrScanner" tabindex="-1" />
                      <x-text-field
                        inputmode="numeric"
                        :name="$t('code')"
                        v-model="verifyCodeForm.code"
                        id="verifyCodeForm.code"
                        ref="verifyCodeForm.code"
                        rules="required|min:4|max:4"
                        class="title"
                        v-mask="'####'"
                        placeholder="####"
                        prepend-inner-icon="mdi-qrcode"
                        :loading="inputLoading"
                        @click:prepend-inner="openQrCamera"
                        :label="$t('code')"
                      />

                      <v-btn
                        type="submit"
                        depressed 
                        block
                        rounded
                        x-large
                        color="primary"
                        class="mt-4 no-caps"
                        :loading="verifyCodeForm.loading" :disabled="verifyCodeForm.loading || invalid"
                      >
                        {{ $t('verify') }} <v-icon right>mdi-arrow-right</v-icon>
                      </v-btn>
                    </div>
                  </v-form>
                </ValidationObserver>
                <p class="text-subtitle-1 mt-4 mb-0" v-if="supportsQrCamera()">{{ $t('redeem_points_footer_qr') }}</p>
                <p class="text-subtitle-1 mt-4 mb-0" v-if="!supportsQrCamera()">{{ $t('redeem_points_footer') }}</p>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card flat tile>
                <v-alert
                  :value="verifyCouponForm.success"
                  text
                  type="success"
                  icon="mdi-check-circle-outline"
                  class="mb-4"
                >
                  <i18n path="redeem_points_success">
                    <template #points>{{ msg.points }}</template>
                    <template #value><span class="font-weight-medium">{{ msg.value }}</span></template>
                    <template #customerNumber><span class="font-weight-medium">{{ msg.customerNumber }}</span></template>
                    <template #transactions>
                      <router-link class="inherit--text" :to="{ name: 'business.transactions' }"><span class="font-weight-medium">{{ $t('transactions').toLowerCase() }}</span></router-link>
                    </template>
                  </i18n>
                </v-alert>
                <p class="text-subtitle-1 mb-4">{{ $t('redeem_coupon_head') }}</p>
                <ValidationObserver ref="verifyCouponForm" v-slot="{ invalid }">
                  <v-form 
                    id="verifyCouponForm"
                    lazy-validation
                    @submit.prevent="verifyCoupon()"
                    autocomplete="off"
                    method="post"
                    accept-charset="UTF-8"
                  >
                    <div class="position-relative">
                      <v-btn 
                        v-if="verifyCouponForm.open"
                        small
                        class="no-caps close-qr-reader"
                        @click="closeQrReader('coupon')"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                      <!-- <qrcode-capture @detect="onDetectCoupon" :multiple="false" id="couponScanner" tabindex="-1" class="d-none" /> -->
                      <qrcode-stream v-if="verifyCouponForm.open" @detect="onDetectCoupon" :multiple="false" id="couponScanner" tabindex="-1" />
                      <x-text-field
                        :name="$t('coupon')"
                        v-model="verifyCouponForm.code"
                        id="verifyCouponForm.code"
                        ref="verifyCouponForm.code"
                        rules="required"
                        class="title d-none"
                        :placeholder="$t('code')"
                        prepend-inner-icon="mdi-qrcode"
                        :loading="inputLoading"
                        @click:prepend-inner="openCouponCamera"
                        :label="$t('code')"
                      />
                      <x-text-field
                        :name="$t('coupon')"
                        v-model="verifyCouponForm.code"
                        id="verifyCouponForm.code"
                        ref="verifyCouponForm.code"
                        rules="required"
                        class="title"
                        :placeholder="$t('code')"
                        prepend-inner-icon="mdi-qrcode"
                        :loading="inputLoading"
                        @click:prepend-inner="openCouponCamera"
                        :label="$t('code')"
                      />

                      <v-btn
                        type="submit"
                        depressed 
                        block
                        rounded
                        x-large
                        color="primary"
                        class="mt-4 no-caps"
                        :loading="verifyCouponForm.loading" :disabled="verifyCouponForm.loading || invalid"
                      >
                        {{ $t('verify') }} <v-icon right>mdi-arrow-right</v-icon>
                      </v-btn>
                    </div>
                  </v-form>
                </ValidationObserver>
                <p class="text-subtitle-1 mt-4 mb-0" v-if="supportsQrCamera()">{{ $t('redeem_points_footer_qr') }}</p>
                <p class="text-subtitle-1 mt-4 mb-0" v-if="!supportsQrCamera()">{{ $t('redeem_points_footer') }}</p>
              </v-card>
            </v-tab-item>
          </v-tabs-items>

          <!-- <v-card flat tile class="content-tabs-card pa-5 mb-0 mb-md-12" v-if="status_code">
            <v-card-text >
              <v-alert
                :value="verifyCodeForm.success"
                type="success"
                class="mb-5"
              >
                <i18n path="redeem_points_success">
                  <template #points>
                    {{ msg.points }}
                  </template>
                  <template #value>
                    {{ msg.value }}
                  </template>
                  <template #customerNumber>
                    {{ msg.customerNumber }}
                  </template>
                  <template #transactions>
                    <router-link class="white--text" :to="{ name: 'business.transactions' }">{{ $t('transactions').toLowerCase() }}</router-link>
                  </template>
                </i18n>
              </v-alert>
              <p class="fg--text subtitle-1">{{ $t('redeem_points_head') }}</p>
              <ValidationObserver ref="verifyCodeForm" v-slot="{ invalid }">
                <v-form 
                  id="verifyCodeForm"
                  lazy-validation
                  @submit.prevent="verifyCode()"
                  autocomplete="off"
                  method="post"
                  accept-charset="UTF-8"
                >
                  <v-row no-gutters>
                    <v-col cols="8">
                      <qrcode-capture @detect="onDetect" :multiple="false" id="qrScanner" tabindex="-1" class="d-none" />
                      <x-text-field
                        inputmode="numeric"
                        :name="$t('code')"
                        v-model="verifyCodeForm.code"
                        id="verifyCodeForm.code"
                        ref="verifyCodeForm.code"
                        rules="required|min:4|max:4"
                        class="title"
                        outlined
                        v-mask="'####'"
                        placeholder="####"
                        :prepend-inner-icon="(supportsQrCamera()) ? 'mdi-qrcode' : ''"
                        :loading="inputLoading"
                        @click:prepend-inner="openQrCamera"
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-btn
                        type="submit"
                        depressed 
                        tile
                        block
                        color="ctaBg ctaFg--text"
                        class="title ma-0 no-caps"
                        height="56px"
                        :loading="verifyCodeForm.loading" :disabled="verifyCodeForm.loading || invalid"
                      >
                        {{ $t('verify') }} <v-icon right>mdi-arrow-right</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </ValidationObserver>
              <p class="mt-0 mb-0 fg--text subtitle-1" v-if="supportsQrCamera()">{{ $t('redeem_points_footer_qr') }}</p>
              <p class="mt-0 mb-0 fg--text subtitle-1" v-if="!supportsQrCamera()">{{ $t('redeem_points_footer') }}</p>
            </v-card-text>
          </v-card> -->

          <!-- <v-card flat tile class="content-tabs-card pa-5 mb-0 mb-md-12" v-if="status_coupon">
            <v-card-text>
              <v-alert
                :value="verifyCouponForm.success"
                type="success"
                class="mb-5"
              >
                <i18n path="redeem_points_success">
                  <template #points>
                    {{ msg.points }}
                  </template>
                  <template #value>
                    {{ msg.value }}
                  </template>
                  <template #customerNumber>
                    {{ msg.customerNumber }}
                  </template>
                  <template #transactions>
                    <router-link class="white--text" :to="{ name: 'business.transactions' }">{{ $t('transactions').toLowerCase() }}</router-link>
                  </template>
                </i18n>
              </v-alert>
              <p class="fg--text subtitle-1">{{ $t('redeem_coupon_head') }}</p>
              <ValidationObserver ref="verifyCouponForm" v-slot="{ invalid }">
                <v-form 
                  id="verifyCouponForm"
                  lazy-validation
                  @submit.prevent="verifyCoupon()"
                  autocomplete="off"
                  method="post"
                  accept-charset="UTF-8"
                >
                  <v-row no-gutters>
                    <v-col cols="8">
                      <qrcode-capture @detect="onDetectCoupon" :multiple="false" id="couponScanner" tabindex="-1" class="d-none" />
                      <x-text-field
                        :name="$t('coupon')"
                        v-model="verifyCouponForm.code"
                        id="verifyCouponForm.code"
                        ref="verifyCouponForm.code"
                        rules="required"
                        class="title d-none"
                        outlined
                        :placeholder="$t('code')"
                        :prepend-inner-icon="(supportsQrCamera()) ? 'mdi-qrcode' : ''"
                        :loading="inputLoading"
                        @click:prepend-inner="openCouponCamera"
                      />
                      <x-text-field
                        :name="$t('coupon')"
                        v-model="verifyCouponForm.code"
                        id="verifyCouponForm.code"
                        ref="verifyCouponForm.code"
                        rules="required"
                        class="title"
                        outlined
                        :placeholder="$t('code')"
                        :prepend-inner-icon="(supportsQrCamera()) ? 'mdi-qrcode' : ''"
                        :loading="inputLoading"
                        @click:prepend-inner="openCouponCamera"
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-btn
                        type="submit"
                        depressed 
                        tile
                        block
                        color="ctaBg ctaFg--text"
                        class="title ma-0 no-caps"
                        height="56px"
                        :loading="verifyCouponForm.loading" :disabled="verifyCouponForm.loading || invalid"
                      >
                        {{ $t('verify') }} <v-icon right>mdi-arrow-right</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </ValidationObserver>
              <p class="mt-0 mb-0 fg--text subtitle-1" v-if="supportsQrCamera()">{{ $t('redeem_points_footer_qr') }}</p>
              <p class="mt-0 mb-0 fg--text subtitle-1" v-if="!supportsQrCamera()">{{ $t('redeem_points_footer') }}</p>
            </v-card-text>
          </v-card> -->
        </div>
      </v-card>
    </v-container>

    <v-dialog v-model="verifyCodeDialog" :fullscreen="$vuetify.breakpoint.xsOnly" max-width="360" scrollable>
      <v-card>
        <v-card-title>{{ $t('redeem_points') }}</v-card-title>
        <v-card-text>
          <p class="text-body-1">
            <i18n path="redeem_points_verify">
              <template #points>
                {{ msg.points }}
              </template>
              <template #value>
                {{ msg.value }}
              </template>
              <template #customerNumber>
                {{ msg.customerNumber }}
              </template>
            </i18n>
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="" class="no-caps" text rounded @click="verifyCodeDialog = false">{{ $t('cancel') }}</v-btn>
          <v-btn color="primary" class="no-caps" rounded depressed :loading="verifyCodeForm.loading" :disabled="verifyCodeForm.loading" @click="verifyCodeDialog = false;processCode()">{{ $t('redeem_points') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="verifyCouponDialog" :fullscreen="$vuetify.breakpoint.xsOnly" max-width="360" scrollable>
      <v-card v-if="confirm_coupon && !success_process_coupon">
        <v-card-title>{{ $t('redeem_points') }}</v-card-title>
        <v-card-text>
          <p class="text-body-1">
            <i18n path="redeem_points_verify_coupon">
              <template #points>
                {{ msg_coupon.points }}
              </template>
              <template #value>
                {{ msg_coupon.value }}
              </template>
              <template #customerNumber>
                {{ msg_coupon.customer_number }}
              </template>
              <template #customerName>
                {{ msg_coupon.customerName }}
              </template>
            </i18n>
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn 
            color="" 
            class="no-caps" 
            text 
            rounded
            @click="verifyCouponDialog = false"
          >{{ $t('cancel') }}</v-btn>
          <v-btn 
            color="primary" 
            class="no-caps" 
            depressed 
            rounded
            :loading="msg_coupon.loading" 
            :disabled="msg_coupon.loading" 
            @click="processCoupon()"
          >{{ $t('redeem_points') }}</v-btn>
        </v-card-actions>
      </v-card>

      <v-card v-if="!confirm_coupon && !success_process_coupon">
        <v-card-title>{{ $t('verify_customer_number') }}</v-card-title>
        <v-card-text class="justify-center">
          <div class="position-relative">
            <v-btn 
              v-if="msg_coupon.open"
              small
              class="no-caps close-qr-reader"
              @click="closeQrReader('customer_number')"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <!-- <qrcode-capture @detect="onDetectCustomer" capture="environment" :multiple="false" id="qr_customer_number" ref="qr_customer_number" tabindex="-1" class="d-none"/> -->
            <qrcode-stream v-if="msg_coupon.open" @detect="onDetectCustomer" capture="environment" :multiple="false" id="qr_customer_number" ref="qr_customer_number" tabindex="-1"/>
            <x-text-field
              inputmode="numeric"
              :name="$t('customer_number')"
              v-model="msg_coupon.customer_number"
              id="customer_number"
              ref="customer_number"
              rules="required|min:15|max:15"
              class="title qrcode-button"
              v-mask="'###-###-###-###'"
              placeholder="###-###-###-###"
              prepend-inner-icon="mdi-qrcode"
              :loading="inputLoading"
              @click:prepend-inner="openQrUpload"
            />
            <v-btn
              @click="verifyCouponWithCustomerNumber"
              depressed 
              rounded
              block
              large
              color="primary"
              class="mt-4 mb-4 no-caps"
              :loading="msg_coupon.loading" :disabled="msg_coupon.loading"
            >
              {{ $t('verify') }}
            </v-btn>
            <p class="text-subtitle-1 mb-0" v-if="supportsQrCamera()">{{ $t('issue_points_footer_qr') }}</p>
            <p class="text-subtitle-1 mb-0" v-if="!supportsQrCamera()">{{ $t('issue_points_footer') }}</p>
          </div>
        </v-card-text>
      </v-card>
      
      <v-card v-if="success_process_coupon">
        <v-card-title>{{ $t('redeem_points_success_alert') }}</v-card-title>
        <v-card-text class="justify-center">
          <div class="">
            <div class="d-flex justify-content-between align-center">
              <span class="text-body-1">{{ $t('redeem_points_success_alert') }}:</span>
              <span class="text-h6"><v-icon color="success">mdi-check</v-icon></span>
            </div>
            <div class="d-flex justify-content-between align-center">
              <span class="text-body-1">{{ $t('points') }}:</span>
              <span class="text-h6">{{ msg_coupon.points }}</span>
            </div>
            <div class="d-flex justify-content-between align-center">
              <span class="text-body-1">{{ $t('discount') }}:</span>
              <span class="text-h6">{{ msg_coupon.value }}</span>
            </div>
            <div class="d-flex justify-content-between align-center">
              <span class="text-body-1">{{ $t('customer_name') }}:</span>
              <span class="text-h6">{{ msg_coupon.customerName }}</span>
            </div>
            <div class="d-flex justify-content-between align-center">
              <span class="text-body-1">{{ $t('customer_number') }}:</span>
              <span class="text-h6">{{ msg_coupon.customer_number }}</span>
            </div>
          </div>
        </v-card-text>
        
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="" rounded text @click.native="cancel" class="no-caps">{{ $t('cancel') }}</v-btn>
      </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>
<script>
import { supportsQrCamera, copyStringToClipboard } from '@/utils/helpers'
import { QrcodeStream } from 'vue-qrcode-reader'
import { mask } from 'vue-the-mask'
// import MenuBusiness from '@/components/ui/MenuBusiness.vue'

export default {
  components: {
    //QrcodeCapture,
    QrcodeStream,
    //MenuBusiness
  },
  data: () => ({
    locale: 'th',
    verifyCodeDialog: false,
    verifyCouponDialog: false,
    // tab: 'tab-0',
    tabImg: 'about:blank',
    tabImgHeight: null,
    tabImgDefaultHeight: 780,
    tabImgAspectRation: 1.7778,
    tabImgs: [],
    inputLoading: false,
    tab: "status_code",
    status_code: true,
    status_coupon: false,
    confirm_coupon: false,
    verifyCodeForm: {
      open: false,
      success: false,
      loading: false,
      code: ''
    },
    verifyCouponForm: {
      open: false,
      success: false,
      loading: false,
      code: ''
    },
    msg: {
      points: 0,
      value: 0,
      customerNumber: ''
    },
    msg_coupon: {
      open: false,
      loading: false,
      points: 0,
      value: 0,
      customer_number: '',
      customerName: '',
    },
    success_process_coupon: false
  }),
  directives: {
    mask
  },
  created () {
    // Set locale
    this.locale = 'th'
    this.moment.locale(this.locale.substr(0,2))

    // Query object
    this.verifyCodeForm.code = this.$route.query.c
  },
  methods: {
    supportsQrCamera,
    copyStringToClipboard,
    formatNumber (number) {
      return new Intl.NumberFormat(this.locale.replace('_', '-')).format(number)
    },
    formatCurrency (number, currency) {
      return new Intl.NumberFormat(this.locale.replace('_', '-'), {style: 'currency', currency: currency}).format(number)
    },
    closeQrReader(item) {
      if(item == 'code'){
        this.verifyCodeForm.open = false
      }
      else if(item == 'coupon'){
        this.verifyCouponForm.open = false
      }
      else if(item == 'customer_number'){
        this.msg_coupon.open = false
      }
    },
    openQrCamera () {
      //this.$el.querySelector('#qrScanner').click()
      this.verifyCodeForm.open = true
    },
    openCouponCamera () {
      //this.$el.querySelector('#couponScanner').click()
      this.verifyCouponForm.open = true
    },
    async onDetect (promise) {
      this.$refs['verifyCodeForm'].reset()
      this.inputLoading = true
      try {
        const {
          imageData,    // raw image data of image/frame
          content,      // decoded String or null
          location      // QR code coordinates or null
        } = await promise
        console.log(content)
        if (content === null) {
          this.$refs['verifyCodeForm.code'].applyResult({
            errors: this.$t('camera_no_qr_found'),
            valid: false,
            failedRules: {}
          })
        } else {
          // const url = new window.URL(content)
          // const urlParams = new window.URLSearchParams(url.search)
          // this.verifyCodeForm.code = urlParams.get('c')
          this.verifyCodeForm.code = content
        }
      } catch (error) {
        this.$refs['verifyCodeForm.code'].applyResult({
          errors: error,
          valid: false,
          failedRules: {}
        })
      } finally {
        this.inputLoading = false
        this.verifyCodeForm.open = false
      }
    },
    async onDetectCoupon (promise) {
      this.$refs['verifyCouponForm'].reset()
      this.inputLoading = true
      try {
        const {
          imageData,    // raw image data of image/frame
          content,      // decoded String or null
          location      // QR code coordinates or null
        } = await promise

        if (content === null) {
          this.$refs['verifyCouponForm.code'].applyResult({
            errors: this.$t('camera_no_qr_found'),
            valid: false,
            failedRules: {}
          })
        } else {
          // const url = new window.URL(content)
          // const urlParams = new window.URLSearchParams(url.search)
          // this.verifyCouponForm.code = urlParams.get('c')
          this.verifyCouponForm.code = content
        }
      } catch (error) {
        this.$refs['verifyCouponForm.code'].applyResult({
          errors: error,
          valid: false,
          failedRules: {}
        })
      } finally {
        this.inputLoading = false
        this.verifyCouponForm.open = false
      }
    },
    openQrUpload () {
      //this.$refs.qr_customer_number.$el.click();
      this.msg_coupon.open = true
    },
    async onDetectCustomer (promise) {
      try {
        const {
          imageData,    // raw image data of image/frame
          content,      // decoded String or null
          location      // QR code coordinates or null
        } = await promise
        if(!content){
          this.$root.$snackbar(this.$t('invalid_qrcode'))
          return false
        }
        this.msg_coupon.customer_number = content
        this.verifyCouponWithCustomerNumber()  
      } catch (error) {
        this.$root.$snackbar(this.$t('invalid_qrcode'))
      } finally {
        this.msg_coupon.open = false
      }
    },
    verifyCouponWithCustomerNumber () {
      //console.log(this.msg_coupon.customer_number)
      if(!this.msg_coupon.customer_number){
        this.$root.$snackbar(this.$t('invalid_value'))
        return false
      }
      this.msg_coupon.loading = true
      this.axios
      .post('/business/redeem/verify-coupon-with-customer-number', {
        customer_number: this.msg_coupon.customer_number,
        code: this.verifyCouponForm.code
      })
      .then(response => {
        if (response.data.status === 'success') {
          //console.log(response.data)
          this.confirm_coupon = true
          this.msg_coupon.points = this.formatNumber(response.data.points)
          this.msg_coupon.value = this.formatCurrency(response.data.value, response.data.currency)
          this.msg_coupon.customer_number = response.data.customer_number
          this.msg_coupon.customerName = response.data.customer_name
        }
      })
      .catch(error => {
        if (error.response.data.status === 'error') {
          this.$root.$snackbar(error.response.data.msg)
        }
      })
      .finally(() => { 
        this.msg_coupon.loading = false
      })
    },
    verifyCode () {
      this.verifyCodeForm.loading = true
      this.axios
      .post('/business/redeem/verify-code', {
        locale: this.locale,
        code: this.verifyCodeForm.code
      })
      .then(response => {
        if (response.data.status === 'success') {
          this.verifyCodeDialog = true
          this.msg.points = this.formatNumber(response.data.points)
          this.msg.value = this.formatCurrency(response.data.value, response.data.currency)
          this.msg.customerNumber = response.data.customer_number
        }
      })
      .catch(error => {
        if (error.response.data.status === 'error') {
          this.errorMsg = error.response.data.error

          let errors = error.response.data.errors || []

          for (let field in errors) {
            this.$refs['verifyCodeForm.' + field].applyResult({
              errors: errors[field],
              valid: false,
              failedRules: {}
            })
          }
        }
      })
      .finally(() => { 
        window.scrollTo(0,0)
        this.verifyCodeForm.loading = false
      })
    },
    processCode () {
      this.verifyCodeForm.loading = true
      this.axios
        .post('/business/redeem/process-code', {
          locale: this.locale,
          code: this.verifyCodeForm.code
        })
        .then(response => {
          if (response.data.status === 'success') {
            this.verifyCodeForm.code = ''
            this.$nextTick(() => this.$refs['verifyCodeForm'].reset())
            this.verifyCodeForm.success = true
            this.msg.points = this.formatNumber(response.data.redeem.points)
            this.msg.value = this.formatCurrency(response.data.redeem.value, response.data.redeem.currency)
            this.msg.customerNumber = response.data.redeem.customer_number
          }
        })
        .catch(error => {
          if (error.response.data.status === 'error') {
            this.errorMsg = error.response.data.error

            let errors = error.response.data.errors || []

            for (let field in errors) {
              this.$refs['verifyCodeForm.' + field].applyResult({
                errors: errors[field],
                valid: false,
                failedRules: {}
              })
            }
          }
        })
        .finally(() => { 
          window.scrollTo(0,0)
          this.verifyCodeForm.loading = false
        })
    },
    changeView (action) {
      if(action == 'status_code'){
        this.status_code = true
        this.status_coupon = false
      }
      else{
        this.status_code = false
        this.status_coupon = true
      }
    },
    cancel (){
      this.verifyCouponDialog = false
    },
    verifyCoupon () {
      this.success_process_coupon = false
      this.verifyCouponForm.loading = true
      this.axios
      .post('/business/redeem/verify-coupon', {
        locale: this.locale,
        code: this.verifyCouponForm.code
      })
      .then(response => {
        if (response.data.status === 'success') {
          //console.log(response.data)
          this.verifyCouponDialog = true
          if(response.data.type == 'multiple_code'){
            this.confirm_coupon = true
            this.msg_coupon.points = this.formatNumber(response.data.points)
            this.msg_coupon.value = this.formatCurrency(response.data.value, response.data.currency)
            this.msg_coupon.customer_number = response.data.customer_number
            this.msg_coupon.customerName = response.data.customer_name
          }
          else{
            if(response.data.no_customer_number){
              this.confirm_coupon = false
              this.msg_coupon.customer_number = ''
            }
            else{
              this.confirm_coupon = true
              this.verifyCouponForm.code = response.data.code
              this.msg_coupon.points = this.formatNumber(response.data.points)
              this.msg_coupon.value = this.formatCurrency(response.data.value, response.data.currency)
              this.msg_coupon.customer_number = response.data.customer_number
              this.msg_coupon.customerName = response.data.customer_name
            }
          }
        }
      })
      .catch(error => {
        if (error.response.data.status === 'error') {
          this.$root.$snackbar(error.response.data.msg)
        }
      })
      .finally(() => { 
        window.scrollTo(0,0)
        this.verifyCouponForm.loading = false
      })
    },
    processCoupon () {
      this.msg_coupon.loading = true
      this.axios
      .post('/business/redeem/process-coupon', {
        customer_number: this.msg_coupon.customer_number,
        code: this.verifyCouponForm.code
      })
      .then(response => {
        if (response.data.status === 'success') {
          //console.log(response.data)
          this.verifyCouponForm.code = ''
          this.success_process_coupon = true
        }
      })
      .catch(error => {
        if (error.response.data.status === 'error') {
          this.$root.$snackbar(error.response.data.msg)
        }
      })
      .finally(() => { 
        window.scrollTo(0,0)
        this.msg_coupon.loading = false
      })
    }
  }
}
</script>
<style scoped>
  .close-qr-reader{
    position: absolute;
    z-index: 1;
    right: 0;
  }
  .position-relative{
    position: relative;
  }
</style>